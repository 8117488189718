@keyframes shipmentslistitemout {
  from {
    transform: translate3d(0, 0, 0);
  }
  30% {
    height: auto;
  }
  70% {
    transform: translate3d(-120%, 0, 0);
  }
  to {
    height: 0;
    transform: translate3d(-120%, 0, 0);
  }
}

@keyframes shipmentslistitemin {
  from {
    height: 0;
    transform: translate3d(120%, 0, 0);
  }
  30% {
    transform: translate3d(120%, 0, 0);
  }
  70% {
    height: auto;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.shipments-leave {
  animation: 750ms shipmentslistitemout forwards;
}
.shipments-leave button:focus {
  outline: none;
}

.shipments-enter {
  animation: 750ms shipmentslistitemin forwards;
}
