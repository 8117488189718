/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol,
td > ul,
td > ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: "HoeflerTextRoman";
  src: url("./static/hinted-HoeflerTextRoman.eot");
  src: local("HoeflerTextRoman Regular"), local("HoeflerTextRoman"), url("./static/hinted-HoeflerTextRoman.eot?#iefix") format("embedded-opentype"), url("./static/hinted-HoeflerTextRoman.woff2") format("woff2"), url("./static/hinted-HoeflerTextRoman.woff") format("woff"), url("./static/hinted-HoeflerTextRoman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@keyframes spinner {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes badge {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
#root .notification-container {
  width: 100%;
  padding: 0;
}
#root .notification {
  margin: 0;
  opacity: 1;
  padding: 15px;
}
#root .notification:before {
  content: none;
}
#root .notification-success {
  background: #97be57;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

input,
select,
textarea,
button {
  font-size: 14px;
  line-height: 18px;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #222;
  background: #e0ded8;
}

h1,
h2 {
  font-family: "HoeflerTextRoman", "Hoefler Text", "Baskerville Old Face", Garamond, "Times New Roman", serif;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

i {
  color: #83786e;
  padding: 6px 0;
}

* {
  box-sizing: border-box;
}

button a {
  color: white;
  font-weight: normal;
}
button a:hover, button a:focus, button a:active {
  text-decoration: none;
  color: white;
  font-weight: normal;
}

button:disabled:focus {
  outline: none;
}
button:disabled svg {
  fill: #ccc;
}

.list-group-item:focus, .list-group-item:hover, .list-group-item:active {
  outline: none;
  border: 1px solid #e78125;
}

.add_photo_alternate-black-18dp {
  background-image: "/static/add_photo_alternate-black-18dp/2x/twotone_add_photo_alternate_black_18dp.png";
}

.spinner {
  max-width: 130px;
  margin: 0 auto;
}

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  position: relative;
  min-width: 100px;
  padding: 0.5em 1em;
  border: 1px solid #9e9e9e;
  background-color: white;
}
select:focus, select:hover, select:active {
  border: 1px solid #e78125;
  outline: none;
}

.search-shipments .DateRangePickerInput {
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(158, 158, 158);
  border-image: initial;
  box-shadow: rgba(129, 129, 129, 0.5) 0px 1px 3px 0px inset;
}

.search-shipments .DateRangePickerInput .DateInput {
  width: calc(50% - 12px);
}

.search-shipments .DateRangePickerInput .DateInput:last-child input {
  text-align: right;
}

.search-shipments .DateInput_input {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.search-shipments .DateInput,
.search-shipments .DateInput_input {
  background: none;
}

.search-shipments .DateInput_input {
  padding: 8px 16px;
  padding-bottom: 6px;
}

.search-shipments .DateInput_input__focused {
  border-bottom-color: #e78125;
}

.search-shipments .CalendarDay__selected_span {
  border-color: #e78125;
  background-color: #e78125;
}

.search-shipments .CalendarDay__selected,
.search-shipments .CalendarDay__selected:active,
.search-shipments .CalendarDay__selected:hover,
.search-shipments .CalendarDay__selected_span:hover,
.search-shipments .CalendarDay__selected_span:focus {
  background-color: #d16e0e;
  border-color: #d16e0e;
}

