.search-shipments .DateRangePickerInput {
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(158, 158, 158);
  border-image: initial;
  box-shadow: rgba(129, 129, 129, 0.5) 0px 1px 3px 0px inset;
}
.search-shipments .DateRangePickerInput .DateInput {
  width: calc(50% - 12px);
}
.search-shipments .DateRangePickerInput .DateInput:last-child input {
  text-align: right;
}
.search-shipments .DateInput_input {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.search-shipments .DateInput,
.search-shipments .DateInput_input {
  background: none;
}

.search-shipments .DateInput_input {
  padding: 8px 16px;
  padding-bottom: 6px;
}

.search-shipments .DateInput_input__focused {
  border-bottom-color: $primaryColor;
}

.search-shipments .CalendarDay__selected_span {
  border-color: $primaryColor;
  background-color: $primaryColor;
}

.search-shipments .CalendarDay__selected,
.search-shipments .CalendarDay__selected:active,
.search-shipments .CalendarDay__selected:hover,
.search-shipments .CalendarDay__selected_span:hover,
.search-shipments .CalendarDay__selected_span:focus {
  background-color: #d16e0e;
  border-color: #d16e0e;
}
