#root {
  .notification-container {
    width: 100%;
    padding: 0;
  }
  .notification {
    margin: 0;
    opacity: 1;
    padding: 15px;
    &:before {
      content: none;
    }
  }
  .notification-success {
    background: $statusGreenColor;
  }
}
