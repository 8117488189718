.panel-enter {
}

@keyframes fadeout {
  from {
    opacity: 0.8;
    height: 100vh;
  }
  99% {
    height: 100vh;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    height: 100vh;
  }
  to {
    opacity: 0.8;
    height: 100vh;
  }
}
