@import 'reboot';
@import 'fonts';
@import 'colors';
@import 'animations';
@import '_notifications';

iframe#webpack-dev-server-client-overlay{display:none!important}

$baseFontSize: 14px;
$baseLineHeight: 18px;

$baseBorder: 1px solid #9e9e9e;
$baseFocusBorder: 1px solid #e78125;

$basePadding: 8px 16px;
$baseTablePadding: 8px;

input,
select,
textarea,
button {
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #222;
  background: $light;
}
h1,
h2 {
  font-family: 'HoeflerTextRoman', 'Hoefler Text', 'Baskerville Old Face', Garamond, 'Times New Roman', serif;
}
h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

i {
  color: $darkColor;
  padding: 6px 0;
}

* {
  box-sizing: border-box;
}

button a {
  color: white;
  font-weight: normal;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: white;
    font-weight: normal;
  }
}

button:disabled {
  &:focus {
    outline: none;
  }
  svg {
    fill: #ccc;
  }
}

.list-group-item {
  &:focus,
  &:hover,
  &:active {
    outline: none;
    border: $baseFocusBorder;
  }
}

.add_photo_alternate-black-18dp
{
    background-image: '/static/add_photo_alternate-black-18dp/2x/twotone_add_photo_alternate_black_18dp.png';
}

.spinner {
  max-width: 130px;
  margin: 0 auto;
}

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  position: relative;
  min-width: 100px;
  padding: 0.5em 1em;
  border: $baseBorder;
  background-color: white;
  &:focus,
  &:hover,
  &:active {
    border: $baseFocusBorder;
    outline: none;
  }
}

@import 'date-picker-overrides';
