@font-face {
  font-family: 'HoeflerTextRoman';
  src: url('./static/hinted-HoeflerTextRoman.eot');
  src: local('HoeflerTextRoman Regular'), local('HoeflerTextRoman'),
    url('./static/hinted-HoeflerTextRoman.eot?#iefix')
      format('embedded-opentype'),
    url('./static/hinted-HoeflerTextRoman.woff2') format('woff2'),
    url('./static/hinted-HoeflerTextRoman.woff') format('woff'),
    url('./static/hinted-HoeflerTextRoman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
