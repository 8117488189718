@keyframes spinner {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes badge {
  0% {
    box-shadow: 0 0 0 0 rgba(#fff, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(#fff, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}
